@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --paddingTop: $tokenSpacingLarge3X;

  font-size: $tokenFontSizesSmall1X;
  line-height: $tokenLineHeightsSmall1X;
  padding-bottom: $tokenSpacingSmall1X;
  padding-top: var(--paddingTop);

  @media (--breakpointMediumFrom) {
    --paddingTop: $tokenSpacingSmall2X;

    align-items: center;
    display: grid;
    gap: $tokenSpacingLayoutGridGutterLarge1X;
    grid-template-columns: 1fr auto;
  }

  & a {
    --color: $tokenColorsTextBody;

    color: var(--color);
    display: inline-block;

    &:hover,
    &:focus {
      --color: $tokenColorsInteractionHover;
    }

    &:not(:last-child) {
      margin-right: $tokenSpacingDefault;

      @media (--breakpointMediumFrom) {
        margin: 0;
      }
    }
  }

  & .socialLinks {
    --marginBottom: $tokenSpacingDefault;

    display: flex;
    gap: $tokenSpacingInteractionTapTarget;
    justify-content: center;
    margin-bottom: var(--marginBottom);

    @media (--breakpointMediumFrom) {
      --marginBottom: 0;
    }

    /* TODO (DS-293): Until DS-293 is complete, handle it here. */
    & a[href] {
      justify-content: center;
    }
  }

  & .copyright {
    --itemSpacing: $tokenSpacingDefault;

    text-align: center;

    @media (--breakpointMediumFrom) {
      align-items: center;
      display: flex;
      gap: var(--itemSpacing);
      order: -1;
    }

    @media (min-width: $tokenSizingBreakpointLarge1XFrom) {
      --itemSpacing: $tokenSpacingLarge1X;
    }

    & a:first-child {
      --marginRight: $tokenSpacingInteractionTapTarget;

      margin-right: var(--marginRight);

      @media (--breakpointMediumFrom) {
        --marginRight: 0;
      }
    }
  }
}
