@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.error {
  color: $tokenColorsStatusError;
  text-align: center;
}

.privacyLinks {
  display: flex;
  margin-top: $tokenSpacingSmall4X;

  & > :first-child {
    margin-right: $tokenSpacingSmall2X;
  }

  & a {
    font-size: $tokenFontSizesSmall1X;
  }
}

.resend {
  align-items: center;
  display: flex;
  margin: $tokenSpacingDefault 0;

  & svg {
    color: $tokenColorsBrandDefault;
  }
}
