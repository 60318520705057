@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --largeFromViewport (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  position: relative;

  & input:not(:focus, :disabled) {
    $borderWidth: $tokenBorderWidthElementFormInputDefault;
    $paddingSides: $tokenSpacingElementFormTextInputPadding;
    $paddingSidesHover: calc($paddingSides - $borderWidth);

    --borderColor: $tokenColorsStatusError;
    --borderWidth: $tokenBorderWidthElementFormInputHover;
    --paddingSides: $paddingSidesHover;

    &::placeholder {
      color: $tokenColorsStatusError;
      opacity: 1;
    }
  }

  & button:not(:disabled) svg {
    color: $tokenColorsStatusError;
  }

  & .error {
    color: $tokenColorsStatusError;
    font-size: $tokenFontSizesElementFormValidationMessage;

    @media (--largeFromViewport) {
      position: absolute;
    }
  }
}
