@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  $divider: $tokenBorderWidthElementDivider solid $tokenColorsElementDivider;
  $spacing: $tokenSpacingLarge3X;

  --fontWeight: $tokenFontWeightsSemiBold;
  --gridColumnCount: 2;
  --gridColumnGap: $tokenSpacingLayoutGridGutterDefault;
  --gridRowGap: $spacing;

  border-bottom: $divider;
  border-top: $divider;
  column-gap: var(--gridColumnGap);
  display: grid;
  font-weight: var(--fontWeight);
  grid-template-columns: repeat(var(--gridColumnCount), 1fr);
  padding-bottom: $spacing;
  padding-top: $spacing;
  row-gap: var(--gridRowGap);

  &.isContextualLinks {
    --fontWeight: $tokenFontWeightsRegular;
    --gridRowGap: $tokenSpacingInteractionTapTarget;
    --marginBottom: $spacing;

    border: none;
    font-size: $tokenFontSizesSmall1X;
    line-height: $tokenLineHeightsSmall2X;
    margin-bottom: var(--marginBottom);
    padding: 0;
    text-transform: capitalize;

    &:last-of-type {
      --marginBottom: $tokenSpacingLarge5X;
    }
  }

  @media (min-width: $tokenSizingLayoutGridBreakpoint) {
    --gridColumnGap: $tokenSpacingLayoutGridGutterLarge1X;
  }

  @media (min-width: $tokenSizingBreakpointLarge2XFrom) {
    --gridColumnCount: 5;
  }

  /* TODO (DS-293): Until DS-293 is complete, handle it here. */
  & a[href] {
    align-items: start !important;
    justify-content: start !important;
    padding-top: calc($tokenSizingInteractionTapTarget / 3);
  }
}
