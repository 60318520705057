/* stylelint-disable */
@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';
@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  bottom: 20px;
  height: 50px;
  margin: 10px 20px;
  position: fixed;
  right: 0;
  z-index: 1;

  & button {
    --borderRadius: 100%;
    --flexDirection: column;
    --justifyContent: center;
    --size: 48px;

    align-items: center;
    background-color: #1f73b7;
    border-radius: var(--borderRadius);
    color: #fff;
    display: flex;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu,
      Cantarell, Helvetica Neue, Arial, sans-serif;
    flex-direction: var(--flexDirection);
    font-size: 15px;
    font-weight: $tokenFontWeightsBold;
    height: var(--size);
    justify-content: var(--justifyContent);
    letter-spacing: 0.6;
    width: var(--size);

    @media (--breakpointMediumFrom) {
      --borderRadius: 999rem;
      --flexDirection: row;
      --justifyContent: initial;
      --size: auto;

      column-gap: 8px;
      padding: 13px 22px;
    }

    & svg {
      $size: 20px;
      width: $size;
      height: $size;
    }

    & > span {
      display: none;

      @media (--breakpointMediumFrom) {
        display: block;
      }
    }

    &:disabled {
      cursor: wait;
      background-color: $tokenColorsGreyscaleLight1X;
    }
  }
}
