@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  & ul li {
    font-size: $tokenFontSizesSmall1X;
    font-weight: $tokenFontWeightsRegular;

    &:not(:last-child) {
      margin-bottom: $tokenSpacingInteractionTapTarget;
    }

    /* TODO (DS-293): Until DS-293 is complete, handle it here. */
    & .imageLink {
      align-items: center !important;
      padding-top: 0;
    }
  }
}
