@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.error {
  color: $tokenColorsStatusError;
  text-align: center;
}

.divider {
  align-items: center;
  display: flex;
  gap: $tokenSpacingDefault;

  & .hr {
    background-color: $tokenColorsGreyscaleLight4X;
    flex: 1;
    height: $tokenSpacingSmall4X;
  }
}

.dialogContainer {
  display: grid;
  gap: $tokenSpacingLarge1X;

  & h4 {
    margin-bottom: $tokenSpacingSmall3X;
    padding: 0 $tokenSpacingLarge5X;
  }

  & .login {
    display: flex;
    gap: $tokenSpacingSmall2X;
  }
}

.form {
  display: grid;
  gap: $tokenSpacingDefault;
}
